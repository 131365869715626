.header {
  position: absolute;
  z-index: 11;
  left: 0;
  right: 0;
  background-color: white;
  transition: .3s linear;
  &.menu__scroll {
    position: fixed; } }

.header__bottom {
  padding: 20px 0;
  border-top: 1px solid $dark;
  border-bottom: 1px solid $dark;
  @include sm-block {
    padding: 10px 0; }
  @include xxs-block {
    border-top-color: transparent; }
  .section__container {
    display: flex;
    justify-content: space-between;
    align-items: center; } }
.header__logo {
  display: flex;
  align-items: center;
  flex-basis: 22%;
  @include lg-block {
    flex-basis: 20%; }
  @include mdx-block {
    flex-basis: 40%; }
  @include sm-block {
    flex-basis: 50%; } }
.header__logo-img {
  @include mdx-block {
    max-width: 210px; }
  @include sm-block {
    max-width: 150px; } }
.menu__phone {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  z-index: 4;
  display: none;
  cursor: pointer;
  @include sm-block {
    display: block; }
  &.active {
    position: fixed;
    .menu__phone-btn {
      background-color: transparent;
      &:before {
        top: 0;
        transform: rotate(45deg);
        background-color: #fff; }
      &:after {
        bottom: 0;
        transform: rotate(-45deg);
        background-color: #fff; } } } }
.menu__phone-btn {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: $dark;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: .3s linear;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: $dark;
    position: absolute;
    top: 10px;
    transition: .3s linear; }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: $dark;
    position: absolute;
    bottom: 10px;
    transition: .3s linear; } }

.header__wrap {
  flex-basis: 78%;
  display: flex;
  align-items: center;
  @include lg-block {
    flex-basis: 80%; }
  @include mdx-block {
    flex-basis: 60%; }
  @include sm-block {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.8);
    z-index: 3; } }
.nav {
  flex-basis: 70%;
  @include mdx-block {
    flex-basis: 100%; }
  @include sm-block {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end; } }

.nav__list {
  display: flex;
  justify-content: center;
  @include mdx-block {
    justify-content: flex-end; }
  @include sm-block {
    flex-direction: column;
    justify-content: center; } }
.nav__item {
  flex: 1;
  text-align: center;
  @include sm-block {
    margin-bottom: 15px; } }
.nav__link {
  font-size: 17px;
  color: $dark;
  transition: .3s linear;
  @include lg-block {
    font-size: 16px; }
  @include sm-block {
    color: #fff; }
  &:hover {
    text-shadow: 0 0 1px; } }

.header__contacts {
  flex-basis: 30%;
  text-align: right;
  @include mdx-block {
    display: none; }
  @include sm-block {
    display: block;
    text-align: center; } }
.header__phone {
  font-size: 24px;
  @include font-face(lora);
  color: $dark;
  display: block;
  @include lg-block {
    font-size: 20px; } }
.header__call {
  margin-left: 25px;
  color: $dark;
  border-bottom: 1px solid $dark; }
