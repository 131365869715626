.clients {
  padding-top: 0;
  .circle__mini {
    width: 24px;
    height: 24px; }
  .circle__mini.circle__left {
    top: 20px; }
  .circle__average {} }
.clietns__list {
  .about__item {
    @include xxs-block {
      flex-basis: 50%; } } }
