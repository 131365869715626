.footer {
  margin-top: 100px;
  background-color: #93E8B5;
  position: relative;
  z-index: 2;
  .circle__left {
    bottom: 60px; }
  .circle__right {
    top: 100px; }
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100px;
    top: -50px;
    border-radius: 50%;
    background-color: #93E8B5;
    box-shadow: 0 -4px 5px 0px rgba(0,0,0,.1);
    @include mdx-block {
      top: 470px; }
    @include sm-block {
      top: 430px; }
    @include xs-block {
      top: 440px; } }
  @include mdx-block {
    background: linear-gradient(0deg, #93E8B5 0%, #93E8B5 37%, $blue 37% ,  $blue 100% ); }
  @include sm-block {
    background: linear-gradient(0deg, #93E8B5 0%, #93E8B5 43%, $blue 43% ,  $blue 100% ); }
  @include xxs-block {
    margin-top: 50px; }
  .section__container {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap; } }
.footer__left {
  padding: 60px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 50px;
  flex-basis: 60%;
  @include mdx-block {
    order: 2;
    flex-basis: 100%;
    padding-right: 0;
    align-items: center; } }
.footer__right {
  flex-basis: 40%;
  @include mdx-block {
    order: 1;
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 20px; } }
.footer__logo {
  flex-basis: 100%; }
.footer__addres {
  margin-top: 50px; }
.footer__addres-list {
  display: flex;
  flex-direction: column; }
.footer__addres-item {
    font-size: 18px;
    color: $dark;
    margin-bottom: 20px;
    padding-left: 50px;
    position: relative;
    transition: .3s linear;
    @include lg-block {
      font-size: 16px; }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      width: 20px;
      height: 21px;
      background: url('/assets/images/footer/location.svg') center center no-repeat;
      background-size: contain; }
    a {
      color: $dark; } }
.footer__addres-item_watch {
  &:before {
    background-image: url('/assets/images/footer/watch.svg'); } }
.footer__addres-item_phone {
  &:before {
    background-image: url('/assets/images/footer/phones.svg'); } }
.footer__addres-item_mail {
  &:before {
    background-image: url('/assets/images/footer/mail.svg'); } }
.footer__wrap {
  margin-top: -145px;
  max-width: 415px;
  display: inline-block;
  padding: 40px 20px;
  background-color: #fff;
  text-align: left;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0,0,0,.5);
  @include mdx-block {
    margin-top: 0; }
  @include sm-block {
    background-color: transparent;
    box-shadow: 0 0 0; }
  .form__title {
    text-align: center; }
  .form__elem {
    padding-right: 0;
    margin-bottom: 15px; }
  .form__elem-agreement {
    margin-bottom: 15px; }
  .form__contorls {
    text-align: center; } }
