.service {
  padding-top: 160px;
  padding-bottom: 0;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -9%;
    width: 116%;
    top: -70px;
    border-radius: 50%;
    padding-top: 10%;
    background-color: #ffffff;
    box-shadow: 0 5px 5px 0px rgba(0,0,0,.1);
    @include mdx-block {
      top: -50px; }
    @include sm-block {
      top: -30px; } }
  @include lg-block {
    padding-top: 80px; }
  @include sm-block {
    padding-top: 80px; }
  @include xxs-block {
    padding-top: 60px; }
  .section__container {
    position: relative;
    z-index: 1; } }
.service__tabs-list {
  display: flex;
  flex-wrap: wrap;
  @include sm-block {
    justify-content: center; } }
.tabs__item {
  flex-basis: 20%;
  @include sm-block {
    flex-basis: 33%; }
  @include xxs-block {
    flex-basis: 100%;
    &:first-child {
      .tabs__trigger {
        border-top-color: transparent; } } }
  &.active {
    .tabs__trigger {
      background-color: #f0f5fc; } } }
.tabs__trigger {
  font-size: 24px;
  @include font-face(lora);
  color: $dark;
  background-color: transparent;
  cursor: pointer;
  padding: 19px 15px;
  border-radius: 25px 25px 0 0;
  transition: .3s linear;
  @include lg-block {
    font-size: 18px; }
  @include sm-block {
    padding: 12px 10px; }
  @include xxs-block {
    font-size: 16px;
    border-radius: 0;
    border-top: 1px solid $dark; } }

.tabs__content {
  display: none; }
.service__cards {
  @include xxs-block {
    display: none; } }
.service__item {
  display: none;
  &.active {
    display: block; } }
.service__content-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 30px; }
.service__content-item {
  flex-basis: 26%;
  margin: 45px 0;
  display: flex;
  align-items: center;
  @include mdx-block {
    flex-basis: 33%;
    margin: 35px 0; }
  @include xxs-block {
    flex-basis: 50%;
    margin: 20px 0;
    padding-left: 20px; }
  @include xs-block {
    flex-basis: 100%; } }
.service__icon {
  width: 100px;
  height: 100px;
  background-image: url('/assets/images/service/rats.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 20px;
  @include lg-block {
    width: 70px;
    height: 70px; }
  @include sm-block {
    width: 50px;
    height: 50px; } }
.service__icon_flies {
  background-image: url('/assets/images/service/flies.svg'); }
.service__icon_ant {
  background-image: url('/assets/images/service/ant.svg'); }
.service__icon_mole {
  background-image: url('/assets/images/service/mole.svg'); }
.service__icon_midge {
  background-image: url('/assets/images/service/midge.svg'); }
.service__icon_virus {
  background-image: url('/assets/images/service/virus.svg'); }
.service__icon_bat {
  background-image: url('/assets/images/service/bat.svg'); }
.service__icon_wasp {
  background-image: url('/assets/images/service/wasp.svg'); }
.service__icon_mildew {
  background-image: url('/assets/images/service/mildew.svg'); }
.service__icon_scales {
  background-image: url('/assets/images/service/scales.svg'); }
.service__icon_bedbug {
  background-image: url('/assets/images/service/bedbug.svg'); }
.service__icon_scent {
  background-image: url('/assets/images/service/scent.svg'); }


.service__icon_bacteria {
  background-image: url('/assets/images/service/bacteria.svg'); }//бактерия
.service__icon_bambulbis {
  background-image: url('/assets/images/service/bambulbis.svg'); }//шмели
.service__icon_bloxa {
  background-image: url('/assets/images/service/bloxa.svg'); }//блоха
.service__icon_cornet {
  background-image: url('/assets/images/service/cornet.svg'); }//шершень
.service__icon_corpssmell {
  background-image: url('/assets/images/service/corpssmell.svg'); }//трупный запах
.service__icon_graundmause {
  background-image: url('/assets/images/service/graundmause.svg'); }//землеройка
.service__icon_kleu {
  background-image: url('/assets/images/service/kleu.svg'); }//клещь
.service__icon_koroed {
  background-image: url('/assets/images/service/koroed.svg'); }//короед
.service__icon_medvedka {
  background-image: url('/assets/images/service/medvedka.svg'); }//медведка
.service__icon_mokrui {
  background-image: url('/assets/images/service/mokrui.svg'); }//мокрица
.service__icon_moth {
  background-image: url('/assets/images/service/moth.svg'); }//моль
.service__icon_parasites {
  background-image: url('/assets/images/service/parasites.svg'); }//паразиты
.service__icon_roaches {
  background-image: url('/assets/images/service/roaches.svg'); }//таракан
.service__icon_schrum {
  background-image: url('/assets/images/service/schrum.svg'); }//грибок
.service__icon_scineater {
  background-image: url('/assets/images/service/scineater.svg'); }//кожеед
.service__icon_smell {
  background-image: url('/assets/images/service/smell.svg'); }//запах
.service__icon_snakes {
  background-image: url('/assets/images/service/snakes.svg'); }//змеи
.service__icon_woodbag {
  background-image: url('/assets/images/service/woodbag.svg'); }//древоточец
