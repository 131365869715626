@font-face {
  font-family: 'Roboto';
  src: url("/assets/fonts/Roboto-Regular.woff") format("woff"), url("/assets/fonts/Roboto-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lora';
  src: url("/assets/fonts/Lora-Regular.woff") format("woff"), url("/assets/fonts/Lora-Regular.ttf") format("ttf"), url("/assets/fonts/Lora-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  font-size: 16px;
}

body {
  height: 100%;
  line-height: 1.42;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: 'Roboto';
  color: #313C50;
}

section {
  position: relative;
  width: 100%;
  overflow: hidden;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

button {
  cursor: pointer;
}

button:focus,
input:focus,
a:focus,
textarea:focus {
  outline: 0;
  text-decoration: none;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  font-weight: normal;
}

img {
  height: auto;
  max-width: 100%;
}

.wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  overflow: hidden;
}

.footer {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.section {
  padding: 80px 0;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.section__container {
  max-width: 1170px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.section__head {
  text-align: center;
}

.section__title {
  font-size: 30px;
  color: #313C50;
  font-family: 'Lora';
  position: relative;
  padding-bottom: 20px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.section__title:after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background-color: #00D7A7;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.section__body {
  text-align: center;
  margin-top: 70px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.section__text {
  font-size: 18px;
  color: #313C50;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.section__btn {
  width: 100%;
  max-width: 270px;
  font-size: 18px;
  color: #4C628A;
  border: 1px solid #4c628a;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  background-color: transparent;
  padding: 18px 15px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.section__btn:hover {
  border-color: #00D7A7;
  background-color: #00D7A7;
  color: #ffffff;
}

.section__btn_green {
  background-color: #00D7A7;
  border-color: #00D7A7;
  color: #fff;
}

.section__btn_green:hover {
  border-color: #00a47f;
  background-color: #00a47f;
}

.circle {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  position: absolute;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.circle__mini {
  width: 16px;
  height: 16px;
}

.circle__small {
  width: 40px;
  height: 40px;
}

.circle__average {
  width: 75px;
  height: 75px;
}

.circle__left {
  left: 25px;
  bottom: 150px;
}

.circle__right {
  right: 25px;
  top: 130px;
}

.circle__big {
  width: 138px;
  height: 138px;
}

.circle__left_minus {
  left: -60px;
  bottom: 150px;
}

.circle__right_minus {
  right: -60px;
  top: 130px;
}

.services-slider_prev {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.services-slider_prev::before {
  content: "\f053";
  font-family: 'FontAwesome';
  display: block;
  font-size: 26px;
  color: #000;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.services-slider_next {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.services-slider_next::before {
  content: "\f054";
  font-family: 'FontAwesome';
  display: block;
  font-size: 26px;
  color: #000;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.header {
  position: absolute;
  z-index: 11;
  left: 0;
  right: 0;
  background-color: white;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.header.menu__scroll {
  position: fixed;
}

.header__bottom {
  padding: 20px 0;
  border-top: 1px solid #313C50;
  border-bottom: 1px solid #313C50;
}

.header__bottom .section__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header__logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-basis: 22%;
  -ms-flex-preferred-size: 22%;
  flex-basis: 22%;
}

.menu__phone {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  z-index: 4;
  display: none;
  cursor: pointer;
}

.menu__phone.active {
  position: fixed;
}

.menu__phone.active .menu__phone-btn {
  background-color: transparent;
}

.menu__phone.active .menu__phone-btn:before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #fff;
}

.menu__phone.active .menu__phone-btn:after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #fff;
}

.menu__phone-btn {
  width: 100%;
  height: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #313C50;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.menu__phone-btn:before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #313C50;
  position: absolute;
  top: 10px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.menu__phone-btn:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #313C50;
  position: absolute;
  bottom: 10px;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.header__wrap {
  -webkit-flex-basis: 78%;
  -ms-flex-preferred-size: 78%;
  flex-basis: 78%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav {
  -webkit-flex-basis: 70%;
  -ms-flex-preferred-size: 70%;
  flex-basis: 70%;
}

.nav__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav__item {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.nav__link {
  font-size: 17px;
  color: #313C50;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.nav__link:hover {
  text-shadow: 0 0 1px;
}

.header__contacts {
  -webkit-flex-basis: 30%;
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
  text-align: right;
}

.header__phone {
  font-size: 24px;
  font-family: 'Lora';
  color: #313C50;
  display: block;
}

.header__call {
  margin-left: 25px;
  color: #313C50;
  border-bottom: 1px solid #313C50;
}

.hero {
  padding-top: 100px;
  background: url("/assets/images/hero/hero-bg.jpg");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center bottom, 250px 0px;
}

.hero:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(138, 212, 251, 0.9);
}

.hero__content {
  position: relative;
  text-align: center;
}

.hero__wrap {
  max-width: 500px;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-top: 120px;
}

.hero__head {
  padding-bottom: 80px;
}

.hero__title {
  padding-bottom: 0;
}

.hero__title:after {
  display: none;
}

.hero__text {
  padding-bottom: 40px;
}

.hero__btn {
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}

.hero__forms {
  margin-top: 130px;
  background-color: #94e8b6;
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
}

.hero__forms .section__container {
  position: relative;
  z-index: 1;
}

.hero__forms:after {
  content: '';
  display: block;
  position: absolute;
  left: -8%;
  width: 116%;
  top: -80px;
  bottom: 0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding-top: 10%;
  background-color: #94e8b6;
  -webkit-box-shadow: 0 -4px 11px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -4px 11px 0px rgba(0, 0, 0, 0.1);
}

.hero__forms .form {
  background-color: #fff;
  padding: 30px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}

.hero__forms .form .form__title {
  font-family: 'Lora';
  font-size: 20px;
}

.hero__forms .form .form__title:after {
  display: none;
}

.about {
  padding-bottom: 0px;
}

.about__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.about__item {
  margin-bottom: 30px;
  -webkit-flex-basis: 26%;
  -ms-flex-preferred-size: 26%;
  flex-basis: 26%;
}

.about__icon {
  width: 150px;
  height: 150px;
  background-image: url("/assets/images/icon/circle.svg");
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
  -webkit-transition: .2s linear;
  -o-transition: .2s linear;
  transition: .2s linear;
}

.about__icon:before {
  content: '';
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.about__icon_green {
  background-image: url("/assets/images/icon/circle-green.svg");
}

.about__icon_blue {
  background-image: url("/assets/images/icon/circle-blue.svg");
}

.about__icon_specialist:before {
  background-image: url("/assets/images/about/professional.svg");
}

.about__icon_agreement:before {
  background-image: url("/assets/images/about/contract.svg");
}

.about__icon_preparation:before {
  background-image: url("/assets/images/about/preparation.svg");
}

.about__icon_like:before {
  background-image: url("/assets/images/about/like.svg");
}

.about__icon_discont:before {
  background-image: url("/assets/images/about/discount.svg");
}

.about__icon_time:before {
  background-image: url("/assets/images/about/time.svg");
}

.about__icon_hotel:before {
  background-image: url("/assets/images/clients/hotel.svg");
}

.about__icon_home:before {
  background-image: url("/assets/images/clients/home.svg");
}

.about__icon_enterprise:before {
  background-image: url("/assets/images/clients/enterprise.svg");
}

.about__icon_apartment:before {
  background-image: url("/assets/images/clients/apartment.svg");
}

.service {
  padding-top: 160px;
  padding-bottom: 0;
}

.service:after {
  content: '';
  display: block;
  position: absolute;
  left: -9%;
  width: 116%;
  top: -70px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding-top: 10%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.1);
}

.service .section__container {
  position: relative;
  z-index: 1;
}

.service__tabs-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tabs__item {
  -webkit-flex-basis: 20%;
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
}

.tabs__item.active .tabs__trigger {
  background-color: #f0f5fc;
}

.tabs__trigger {
  font-size: 24px;
  font-family: 'Lora';
  color: #313C50;
  background-color: transparent;
  cursor: pointer;
  padding: 19px 15px;
  -webkit-border-radius: 25px 25px 0 0;
  border-radius: 25px 25px 0 0;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.tabs__content {
  display: none;
}

.service__item {
  display: none;
}

.service__item.active {
  display: block;
}

.service__content-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 30px;
}

.service__content-item {
  -webkit-flex-basis: 26%;
  -ms-flex-preferred-size: 26%;
  flex-basis: 26%;
  margin: 45px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.service__icon {
  width: 100px;
  height: 100px;
  background-image: url("/assets/images/service/rats.svg");
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  margin-right: 20px;
}

.service__icon_flies {
  background-image: url("/assets/images/service/flies.svg");
}

.service__icon_ant {
  background-image: url("/assets/images/service/ant.svg");
}

.service__icon_mole {
  background-image: url("/assets/images/service/mole.svg");
}

.service__icon_midge {
  background-image: url("/assets/images/service/midge.svg");
}

.service__icon_virus {
  background-image: url("/assets/images/service/virus.svg");
}

.service__icon_bat {
  background-image: url("/assets/images/service/bat.svg");
}

.service__icon_wasp {
  background-image: url("/assets/images/service/wasp.svg");
}

.service__icon_mildew {
  background-image: url("/assets/images/service/mildew.svg");
}

.service__icon_scales {
  background-image: url("/assets/images/service/scales.svg");
}

.service__icon_bedbug {
  background-image: url("/assets/images/service/bedbug.svg");
}

.service__icon_scent {
  background-image: url("/assets/images/service/scent.svg");
}

.service__icon_bacteria {
  background-image: url("/assets/images/service/bacteria.svg");
}

.service__icon_bambulbis {
  background-image: url("/assets/images/service/bambulbis.svg");
}

.service__icon_bloxa {
  background-image: url("/assets/images/service/bloxa.svg");
}

.service__icon_cornet {
  background-image: url("/assets/images/service/cornet.svg");
}

.service__icon_corpssmell {
  background-image: url("/assets/images/service/corpssmell.svg");
}

.service__icon_graundmause {
  background-image: url("/assets/images/service/graundmause.svg");
}

.service__icon_kleu {
  background-image: url("/assets/images/service/kleu.svg");
}

.service__icon_koroed {
  background-image: url("/assets/images/service/koroed.svg");
}

.service__icon_medvedka {
  background-image: url("/assets/images/service/medvedka.svg");
}

.service__icon_mokrui {
  background-image: url("/assets/images/service/mokrui.svg");
}

.service__icon_moth {
  background-image: url("/assets/images/service/moth.svg");
}

.service__icon_parasites {
  background-image: url("/assets/images/service/parasites.svg");
}

.service__icon_roaches {
  background-image: url("/assets/images/service/roaches.svg");
}

.service__icon_schrum {
  background-image: url("/assets/images/service/schrum.svg");
}

.service__icon_scineater {
  background-image: url("/assets/images/service/scineater.svg");
}

.service__icon_smell {
  background-image: url("/assets/images/service/smell.svg");
}

.service__icon_snakes {
  background-image: url("/assets/images/service/snakes.svg");
}

.service__icon_woodbag {
  background-image: url("/assets/images/service/woodbag.svg");
}

.price {
  background-color: #8ad4fb;
  padding: 160px 0;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.price:before {
  content: '';
  display: block;
  position: absolute;
  left: -9%;
  width: 116%;
  top: -70px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding-top: 10%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.1);
}

.price:after {
  content: '';
  display: block;
  position: absolute;
  left: -9%;
  width: 116%;
  bottom: -70px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding-top: 10%;
  background-color: #ffffff;
  -webkit-box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.1);
}

.price .circle__big {
  top: 45%;
}

.price .circle__small {
  bottom: 30%;
}

.price .section__container {
  position: relative;
}

.price .section__container .circle__mini {
  top: 50px;
}

.price__title {
  margin-bottom: 20px;
}

.price__title:after {
  background-color: #fff;
}

.price__table {
  margin-bottom: 70px;
}

.price__list {
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #f0f5fc;
  border: 1px solid #313C50;
  -webkit-border-radius: 25px 25px 0 0;
  border-radius: 25px 25px 0 0;
}

.price__list_white {
  -webkit-border-radius: 0 0 25px 25px;
  border-radius: 0 0 25px 25px;
  background-color: #fff;
  border-top-color: transparent;
}

.price__item {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 19px 10px;
  background-color: #fff;
  border: 1px solid transparent;
  border-right-color: #313C50;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.price__item:last-child {
  border-right-color: transparent;
}

.price__btn {
  margin: 0 15px;
}

.cost {
  padding-top: 0;
  padding-bottom: 0;
}

.cost .circle__average {
  left: -35px;
  top: 250px;
}

.cost__box {
  margin-bottom: 50px;
}

.cost__box .section__body {
  margin-top: 50px;
}

.cost__title {
  margin-bottom: 20px;
}

.cost__body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cost__left {
  -webkit-flex-basis: 35%;
  -ms-flex-preferred-size: 35%;
  flex-basis: 35%;
  padding-right: 15px;
  border: 1px solid transparent;
}

.cost__right {
  -webkit-flex-basis: 35%;
  -ms-flex-preferred-size: 35%;
  flex-basis: 35%;
  padding-left: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cost__list {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cost__item {
  background-color: #F0F5FC;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #313C50;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 18px 15px;
  margin-bottom: 20px;
}

.cost__item:last-child {
  margin-bottom: 0;
}

.cost__inp-face {
  width: 20px;
  height: 20px;
  padding: 9px;
  border: 1px solid #313C50;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative;
}

.cost__inp-face:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: transparent;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.cost__name {
  margin-right: 10px;
}

.cost__selection {
  min-width: 105px;
  padding: 10px;
  outline: 0;
  border: 1px solid transparent;
  background-color: #F0F5FC;
  border-bottom-color: #313C50;
  color: #313C50;
}

.cost__selection-wrap {
  display: none;
  padding: 10px;
}

.cost__desc {
  height: 100%;
  padding: 20px;
  margin-right: 15px;
  background-color: #F0F5FC;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  border: 1px solid #313C50;
  text-align: left;
  height: 100%;
}

.cost__desc p {
  margin-bottom: 20px;
}

.cost__desc li {
  margin-bottom: 10px;
}

.cost__price {
  margin-left: 15px;
  margin-top: auto;
  -webkit-flex-basis: 30%;
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
}

.cost__price-desc {
  background-color: #00D7A7;
  padding: 19px 15px;
  max-width: 270px;
  width: 100%;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #232524;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.cost__price-controls {
  display: none;
}

.reviews .circle__small.circle__left {
  top: 150px;
}

.reviews .circle__small.circle__right {
  top: initial;
  bottom: 130px;
}

.reviews .section__container {
  position: relative;
}

.reviews .section__container .circle.circle__mini.circl__left {
  top: initial;
  bottom: -40px;
}

.reviews__body {
  position: relative;
}

.reviews__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 30px;
}

.reviews__item {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  border: 1px solid #313C50;
  max-width: 320px;
  height: 605px;
  background-color: #fff;
  margin: 0 20px;
}

.clients {
  padding-top: 0;
}

.clients .circle__mini {
  width: 24px;
  height: 24px;
}

.clients .circle__mini.circle__left {
  top: 20px;
}

.certificates .circle__right {
  top: 60%;
}

.certificates .circle__left {
  top: 40%;
}

.certificat__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 0 30px;
}

.certificat__item {
  margin: 0 20px;
  width: 270px;
  height: 330px;
  background-color: #8ad4fb;
}

.map {
  min-height: 490px;
  background-color: #8ad4fb;
}

.footer {
  margin-top: 100px;
  background-color: #93E8B5;
  position: relative;
  z-index: 2;
}

.footer .circle__left {
  bottom: 60px;
}

.footer .circle__right {
  top: 100px;
}

.footer:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100px;
  top: -50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #93E8B5;
  -webkit-box-shadow: 0 -4px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -4px 5px 0px rgba(0, 0, 0, 0.1);
}

.footer .section__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer__left {
  padding: 60px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-right: 50px;
  -webkit-flex-basis: 60%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
}

.footer__right {
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
}

.footer__logo {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.footer__addres {
  margin-top: 50px;
}

.footer__addres-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer__addres-item {
  font-size: 18px;
  color: #313C50;
  margin-bottom: 20px;
  padding-left: 50px;
  position: relative;
  -webkit-transition: .3s linear;
  -o-transition: .3s linear;
  transition: .3s linear;
}

.footer__addres-item:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  height: 21px;
  background: url("/assets/images/footer/location.svg") center center no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
}

.footer__addres-item a {
  color: #313C50;
}

.footer__addres-item_watch:before {
  background-image: url("/assets/images/footer/watch.svg");
}

.footer__addres-item_phone:before {
  background-image: url("/assets/images/footer/phones.svg");
}

.footer__addres-item_mail:before {
  background-image: url("/assets/images/footer/mail.svg");
}

.footer__wrap {
  margin-top: -145px;
  max-width: 415px;
  display: inline-block;
  padding: 40px 20px;
  background-color: #fff;
  text-align: left;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.footer__wrap .form__title {
  text-align: center;
}

.footer__wrap .form__elem {
  padding-right: 0;
  margin-bottom: 15px;
}

.footer__wrap .form__elem-agreement {
  margin-bottom: 15px;
}

.footer__wrap .form__contorls {
  text-align: center;
}

.form__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.form__title {
  font-size: 24px;
  margin-bottom: 15px;
}

.form__elem {
  -webkit-flex-basis: 38%;
  -ms-flex-preferred-size: 38%;
  flex-basis: 38%;
  padding-right: 30px;
  position: relative;
}

.form__elem:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  background: url("/assets/images/cost/user.svg") center center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.form__elem_phone:before {
  background-image: url("/assets/images/cost/phone.svg");
}

.form__inp {
  width: 100%;
  padding: 18px 50px;
  border: 1px solid #313C50;
  background-color: #f0f5fc;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  max-width: 420px;
  color: #313C50;
}

.form__elem-agreement {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
  -ms-flex-order: 4;
  order: 4;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  text-align: left;
  margin-top: 30px;
}

.form__elem-agreement:before {
  display: none;
}

.form__agreement {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form__agreement-check {
  display: none;
}

.form__agreement-check:checked + .cost__inp-face:before {
  background-color: #313C50;
}

.form__agreement-text {
  margin-left: 10px;
  color: #898EAB;
}

.form__agreement-text a {
  color: #313C50;
  border-bottom: 1px solid #313C50;
}

.form__contorls {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
  -webkit-flex-basis: 24%;
  -ms-flex-preferred-size: 24%;
  flex-basis: 24%;
}

.form__wrapper {
  margin-top: 0;
  background-color: #fff;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
  display: none;
}

.popup .section__container {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.popup__dialog {
  position: relative;
}

.popup__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}

.popup__close-btn {
  width: 100%;
  height: 2px;
  background-color: #00D7A7;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}

.popup__close-btn:before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #00D7A7;
  -webkit-transform: rotate(85deg);
  -ms-transform: rotate(85deg);
  transform: rotate(85deg);
}

.popup__header {
  font-size: 22px;
  text-align: center;
}

.form__desc {
  margin: 15px 0;
  text-align: center;
}

@media screen and (max-width: 1540px) {
  .section__container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .section__container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .section__title {
    font-size: 28px;
  }

  .section__text {
    font-size: 16px;
  }

  .section__btn {
    max-width: 190px;
    font-size: 16px;
    padding: 12px 10px;
  }

  .circle {
    display: none;
  }

  .header__logo {
    -webkit-flex-basis: 20%;
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
  }

  .header__wrap {
    -webkit-flex-basis: 80%;
    -ms-flex-preferred-size: 80%;
    flex-basis: 80%;
  }

  .nav__link {
    font-size: 16px;
  }

  .header__phone {
    font-size: 20px;
  }

  .hero__wrap {
    padding-top: 70px;
  }

  .hero__head {
    padding-bottom: 50px;
  }

  .hero__forms:after {
    top: -70px;
    bottom: 60px;
  }

  .about {
    padding-bottom: 30px;
  }

  .about__icon {
    width: 130px;
    height: 130px;
  }

  .about__icon:before {
    width: 80px;
    height: 80px;
  }

  .service {
    padding-top: 80px;
  }

  .tabs__trigger {
    font-size: 18px;
  }

  .service__icon {
    width: 70px;
    height: 70px;
  }

  .price {
    padding: 80px 0;
  }

  .reviews__item {
    height: 500px;
  }

  .footer__addres-item {
    font-size: 16px;
  }

  .form__title {
    font-size: 24px;
  }

  .form__inp {
    padding: 15px 50px;
  }
}

@media screen and (max-width: 992px) {
  .section__container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .section__btn {
    padding: 12px 7px;
  }

  .reviews__item {
    margin: 0 15px;
  }
}

@media screen and (max-width: 880px) {
  .section__container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header__logo {
    -webkit-flex-basis: 40%;
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
  }

  .header__logo-img {
    max-width: 210px;
  }

  .header__wrap {
    -webkit-flex-basis: 60%;
    -ms-flex-preferred-size: 60%;
    flex-basis: 60%;
  }

  .nav {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .nav__list {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .header__contacts {
    display: none;
  }

  .service:after {
    top: -50px;
  }

  .service__content-item {
    -webkit-flex-basis: 33%;
    -ms-flex-preferred-size: 33%;
    flex-basis: 33%;
    margin: 35px 0;
  }

  .price:before {
    top: -50px;
  }

  .price:after {
    bottom: -50px;
  }

  .footer:before {
    top: 470px;
  }

  .footer {
    background: -webkit-gradient(linear, left bottom, left top, from(#93E8B5), color-stop(37%, #93E8B5), color-stop(37%, #8ad4fb), to(#8ad4fb));
    background: -webkit-linear-gradient(bottom, #93E8B5 0%, #93E8B5 37%, #8ad4fb 37%, #8ad4fb 100%);
    background: -o-linear-gradient(bottom, #93E8B5 0%, #93E8B5 37%, #8ad4fb 37%, #8ad4fb 100%);
    background: linear-gradient(0deg, #93E8B5 0%, #93E8B5 37%, #8ad4fb 37%, #8ad4fb 100%);
  }

  .footer__left {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding-right: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer__right {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .footer__wrap {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .section__title {
    font-size: 24px;
  }

  .header__bottom {
    padding: 10px 0;
  }

  .header__logo {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .header__logo-img {
    max-width: 150px;
  }

  .menu__phone {
    display: block;
  }

  .header__wrap {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 3;
  }

  .nav {
    height: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .nav__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .nav__item {
    margin-bottom: 15px;
  }

  .nav__link {
    color: #fff;
  }

  .header__contacts {
    display: block;
    text-align: center;
  }

  .hero__wrap {
    padding-top: 80px;
  }

  .about__item {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .about__icon {
    width: 110px;
    height: 110px;
  }

  .about__icon:before {
    width: 60px;
    height: 60px;
  }

  .service:after {
    top: -30px;
  }

  .service {
    padding-top: 80px;
  }

  .service__tabs-list {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .tabs__item {
    -webkit-flex-basis: 33%;
    -ms-flex-preferred-size: 33%;
    flex-basis: 33%;
  }

  .tabs__trigger {
    padding: 12px 10px;
  }

  .service__icon {
    width: 50px;
    height: 50px;
  }

  .price {
    padding: 80px 0;
  }

  .price:before {
    top: -30px;
  }

  .price:after {
    bottom: -30px;
  }

  .price__table {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .price__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-border-radius: 25px 0 0 25px;
    border-radius: 25px 0 0 25px;
  }

  .price__list_white {
    border-top-color: #313C50;
    border-left-color: transparent;
    -webkit-border-radius: 0 25px 25px 0;
    border-radius: 0 25px 25px 0;
  }

  .price__item:last-child {
    border-bottom-color: transparent;
  }

  .price__item {
    border-right-color: transparent;
    border-bottom-color: #313C50;
  }

  .cost__body {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .cost__left {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .cost__right {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .cost__price {
    margin-top: 30px;
    margin-left: 0;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    text-align: center;
  }

  .cost__price-desc {
    display: inline-block;
  }

  .reviews__list {
    padding: 0 10px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .reviews__item {
    max-width: 280px;
    height: 400px;
    height: 300px;
  }

  .certificat__list {
    padding: 0 10px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .certificat__item {
    margin-bottom: 15px;
  }

  .footer:before {
    top: 430px;
  }

  .footer {
    background: -webkit-gradient(linear, left bottom, left top, from(#93E8B5), color-stop(43%, #93E8B5), color-stop(43%, #8ad4fb), to(#8ad4fb));
    background: -webkit-linear-gradient(bottom, #93E8B5 0%, #93E8B5 43%, #8ad4fb 43%, #8ad4fb 100%);
    background: -o-linear-gradient(bottom, #93E8B5 0%, #93E8B5 43%, #8ad4fb 43%, #8ad4fb 100%);
    background: linear-gradient(0deg, #93E8B5 0%, #93E8B5 43%, #8ad4fb 43%, #8ad4fb 100%);
  }

  .footer__wrap {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
  }

  .form__wrapper {
    background-color: #fff;
  }
}

@media screen and (max-width: 640px) {
  .section {
    padding: 40px 0;
  }

  .section__title {
    font-size: 20px;
  }

  .section__body {
    margin-top: 30px;
  }

  .header__bottom {
    border-top-color: transparent;
  }

  .hero {
    padding-top: 65px;
    min-height: initial;
    background-image: none;
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(44%, #fff), color-stop(44%, #8ad4fb), to(#8ad4fb));
    background: -webkit-linear-gradient(top, #fff 0%, #fff 44%, #8ad4fb 44%, #8ad4fb 100%);
    background: -o-linear-gradient(top, #fff 0%, #fff 44%, #8ad4fb 44%, #8ad4fb 100%);
    background: linear-gradient(180deg, #fff 0%, #fff 44%, #8ad4fb 44%, #8ad4fb 100%);
  }

  .hero__content {
    width: 100%;
    text-align: center;
  }

  .hero__wrap {
    padding-top: 60px;
  }

  .hero__text {
    padding-bottom: 30px;
  }

  .hero__forms:after {
    top: -50px;
    bottom: 240px;
  }

  .hero__forms .form .form__title {
    text-align: center;
  }

  .hero__forms .form .form__wrap {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .hero__forms .form .form__elem {
    max-width: 330px;
    margin-bottom: 15px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding-right: 0;
  }

  .hero__forms .form .form__contorls {
    text-align: center;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .hero__forms .form .form__btn {
    max-width: 330px;
  }

  .service {
    padding-top: 60px;
  }

  .tabs__item {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .tabs__item:first-child .tabs__trigger {
    border-top-color: transparent;
  }

  .tabs__trigger {
    font-size: 16px;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-top: 1px solid #313C50;
  }

  .service__cards {
    display: none;
  }

  .service__content-item {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    margin: 20px 0;
    padding-left: 20px;
  }

  .price {
    padding: 60px 0;
  }

  .price__btn {
    margin-bottom: 15px;
  }

  .cost__box {
    margin-bottom: 0;
  }

  .cost__box_none {
    display: none;
  }

  .cost__body {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cost__left {
    padding: 10px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin-bottom: 20px;
    padding-right: 0;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    background-color: #F0F5FC;
    border: 1px solid #313C50;
  }

  .cost__right {
    padding-left: 0;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .cost__item {
    padding: 10px;
    margin-bottom: 10px;
    border-color: transparent;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .cost__item .cost__selection {
    display: none;
  }

  .cost__selection-wrap {
    display: block;
  }

  .cost__desc {
    text-align: center;
  }

  .cost__price-desc {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .cost__price-controls {
    display: block;
  }

  .reviews__body {
    margin-top: 70px;
  }

  .reviews__list {
    padding: 0;
  }

  .clietns__list .about__item {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .certificat__list {
    padding: 0;
  }

  .footer {
    margin-top: 50px;
  }
}

@media screen and (max-width: 480px) {
  .section__container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .section__btn {
    max-width: 230px;
    font-size: 16px;
  }

  .hero {
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(40%, #fff), color-stop(40%, #8ad4fb), to(#8ad4fb));
    background: -webkit-linear-gradient(top, #fff 0%, #fff 40%, #8ad4fb 40%, #8ad4fb 100%);
    background: -o-linear-gradient(top, #fff 0%, #fff 40%, #8ad4fb 40%, #8ad4fb 100%);
    background: linear-gradient(180deg, #fff 0%, #fff 40%, #8ad4fb 40%, #8ad4fb 100%);
  }

  .about__item {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .about__icon {
    width: 90px;
    height: 90px;
  }

  .about__icon:before {
    width: 40px;
    height: 40px;
  }

  .service__content-item {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .footer:before {
    top: 440px;
  }
}