.about {
  padding-bottom: 0px;
  @include lg-block {
    padding-bottom: 30px; } }
.about__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
.about__item {
  margin-bottom: 30px;
  flex-basis: 26%;
  @include sm-block {
    flex-basis: 50%; }
  @include xs-block {
    flex-basis: 100%; } }
.about__icon {
  width: 150px;
  height: 150px;
  background-image: url('/assets/images/icon/circle.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
  transition: .2s linear;
  @include lg-block {
    width: 130px;
    height: 130px; }
  @include sm-block {
    width: 110px;
    height: 110px; }
  @include xs-block {
    width: 90px;
    height: 90px; }
  &:before {
    content: '';
    display: block;
    width: 100px;
    height: 100px;
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    @include lg-block {
      width: 80px;
      height: 80px; }
    @include sm-block {
      width: 60px;
      height: 60px; }
    @include xs-block {
      width: 40px;
      height: 40px; } } }

.about__icon_green {
    background-image: url('/assets/images/icon/circle-green.svg'); }
.about__icon_blue {
    background-image: url('/assets/images/icon/circle-blue.svg'); }

.about__icon_specialist {
  &:before {
    background-image: url('/assets/images/about/professional.svg'); } }
.about__icon_agreement {
  &:before {
    background-image: url('/assets/images/about/contract.svg'); } }
.about__icon_preparation {
  &:before {
    background-image: url('/assets/images/about/preparation.svg'); } }
.about__icon_like {
  &:before {
    background-image: url('/assets/images/about/like.svg'); } }
.about__icon_discont {
  &:before {
    background-image: url('/assets/images/about/discount.svg'); } }
.about__icon_time {
  &:before {
    background-image: url('/assets/images/about/time.svg'); } }

.about__icon_hotel {
  &:before {
    background-image: url('/assets/images/clients/hotel.svg'); } }
.about__icon_home {
  &:before {
    background-image: url('/assets/images/clients/home.svg'); } }
.about__icon_enterprise {
  &:before {
    background-image: url('/assets/images/clients/enterprise.svg'); } }
.about__icon_apartment {
  &:before {
    background-image: url('/assets/images/clients/apartment.svg'); } }



