.cost {
  padding-top: 0;
  padding-bottom: 0;
  .circle__average {
    left: -35px;
    top: 250px; } }
.cost__box {
  margin-bottom: 50px;
  @include xxs-block {
    margin-bottom: 0; }
  .section__body {
    margin-top: 50px; } }
.cost__box_none {
  @include xxs-block {
    display: none; } }
.cost__title {
  margin-bottom: 20px; }
.cost__body {
  display: flex;
  justify-content: center;
  @include sm-block {
    flex-wrap: wrap; }
  @include xxs-block {
    align-items: center; } }
.cost__left {
  flex-basis: 35%;
  padding-right: 15px;
  border: 1px solid transparent;
  @include sm-block {
    flex-basis: 50%; }
  @include xxs-block {
    padding: 10px;
    flex-basis: 100%;
    margin-bottom: 20px;
    padding-right: 0;
    border-radius: 20px;
    background-color: #F0F5FC;
    border: 1px solid $dark; } }
.cost__right {
  flex-basis: 35%;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  @include sm-block {
    flex-basis: 50%; }
  @include xxs-block {
    padding-left: 0;
    flex-basis: 100%;
    justify-content: center; } }
.cost__list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.cost__item {
  background-color: #F0F5FC;
  border-radius: 20px;
  display: flex;
  border: 1px solid $dark;
  justify-content: space-between;
  align-items: center;
  padding: 18px 15px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0; }
  @include xxs-block {
    padding: 10px;
    margin-bottom: 10px;
    border-color: transparent;
    justify-content: center;
    .cost__selection {
      display: none; } } }
.cost__inp-face {
  width: 20px;
  height: 20px;
  padding: 9px;
  border: 1px solid $dark;
  border-radius: 50%;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: .3s linear; } }
.cost__name {
  margin-right: 10px; }
.cost__selection {
  min-width: 105px;
  padding: 10px;
  outline: 0;
  border: 1px solid transparent;
  background-color: #F0F5FC;
  border-bottom-color: $dark;
  color: $dark; }

.cost__selection-wrap {
  display: none;
  padding: 10px;
  @include xxs-block {
    display: block; } }


.cost__desc {
  height: 100%;
  padding: 20px;
  margin-right: 15px;
  background-color: #F0F5FC;
  border-radius: 20px;
  border: 1px solid $dark;
  text-align: left;
  height: 100%;
  @include xxs-block {
    text-align: center; }
  p {
    margin-bottom: 20px; }
  li {
    margin-bottom: 10px; } }
.cost__price {
  margin-left: 15px;
  margin-top: auto;
  flex-basis: 30%;
  @include sm-block {
    margin-top: 30px;
    margin-left: 0;
    flex-basis: 100%;
    text-align: center; } }
.cost__price-desc {
  background-color: $green;
  padding: 19px 15px;
  max-width: 270px;
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  color: #232524;
  transition: .3s linear;
  @include sm-block {
    display: inline-block; }
  @include xxs-block {
    font-size: 16px;
    margin-bottom: 15px; } }
.cost__price-controls {
  display: none;
  @include xxs-block {
    display: block; } }
