.reviews {
  .circle__small.circle__left {
    top: 150px; }
  .circle__small.circle__right {
    top: initial;
    bottom: 130px; }
  .section__container {
    position: relative;
    .circle.circle__mini.circl__left {
      top: initial;
      bottom: -40px; } } }
.reviews__body {
  position: relative;
  @include xxs-block {
    margin-top: 70px; } }

.reviews__list {
  display: flex;
  justify-content: center;
  padding: 0 30px;
  @include sm-block {
    padding: 0 10px;
    flex-wrap: wrap; }
  @include xxs-block {
    padding: 0; } }
.reviews__item {
  border-radius: 20px;
  border: 1px solid $dark;
  max-width: 320px;
  height: 605px;
  background-color: #fff;
  margin: 0 20px;
  @include lg-block {
    height: 500px; }
  @include md-block {
    margin: 0 15px; }
  @include sm-block {
    max-width: 280px;
    height: 400px;
    height: 300px; } }
