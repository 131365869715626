.form__wrap {
  display: flex;
  flex-wrap: wrap; }
.form__title {
  font-size: 24px;
  margin-bottom: 15px;
  @include lg-block {
    font-size: 24px; } }
.form__elem {
  flex-basis: 38%;
  padding-right: 30px;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    background: url('/assets/images/cost/user.svg') center center no-repeat;
    background-size: cover;
    top: 50%;
    left: 15px;
    transform: translateY(-50%); } }
.form__elem_phone {
  &:before {
    background-image: url('/assets/images/cost/phone.svg'); } }
.form__inp {
  width: 100%;
  padding: 18px 50px;
  border: 1px solid $dark;
  background-color: #f0f5fc;
  border-radius: 20px;
  max-width: 420px;
  color: $dark;
  @include lg-block {
    padding: 15px 50px; } }
.form__elem-agreement {
  order: 4;
  flex-basis: 100%;
  text-align: left;
  margin-top: 30px;
  &:before {
    display: none; } }
.form__agreement {
  display: flex;
  align-items: center; }
.form__agreement-check {
  display: none;
  &:checked + .cost__inp-face {
    &:before {
      background-color: $dark; } } }
.form__agreement-text {
  margin-left: 10px;
  color: #898EAB;
  a {
    color: $dark;
    border-bottom: 1px solid $dark; } }
.form__contorls {
  order: 3;
  flex-basis: 24%; }
.form__wrapper {
  margin-top: 0;
  background-color: #fff;
  @include sm-block {
    background-color: #fff; } }
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 12;
  display: none;
  .section__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; } }
.popup__dialog {
  position: relative; }
.popup__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px; }
.popup__close-btn {
  width: 100%;
  height: 2px;
  background-color: $green;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: $green;
    transform: rotate(85deg); } }
.popup__header {
  font-size: 22px;
  text-align: center; }
.form__desc {
  margin: 15px 0;
  text-align: center; }


