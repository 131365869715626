* {
	box-sizing: border-box; }

html {
	height: 100%;
	width: 100%;
	font-size: 16px; }

body {
	height: 100%;
	line-height: 1.42;
	-webkit-font-smoothing:antialiased {}
	margin: 0;
	display: flex;
	flex-direction: column;
	@include font-face(rob);
	color: $dark; }
section {
	position: relative;
	width: 100%;
	overflow: hidden; }

ul {
	padding: 0;
	margin: 0; }

li {
	list-style: none; }

a {
	text-decoration: none;
	&:hover {
		text-decoration: none;
		outline: none; } }

button {
	cursor: pointer; }

button, input, a, textarea {
	&:focus {
		outline: 0;
		text-decoration: none; } }

p {
	margin: 0; }

h1, h2, h3, h4, h5 {
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
	font-weight: normal; }

img {
	height: auto;
	max-width: 100%; }

.wrapper {
	flex: 1 0 auto;
	overflow: hidden; }

.footer {
	flex: 0 0 auto; }

.section {
	padding: 80px 0;
	transition: .3s linear;
	@include xxs-block {
		padding: 40px 0; } }
.section__container {
	@include wrapper(); }
.section__head {
	text-align: center; }
.section__title {
	font-size: 30px;
	color: $dark;
	@include font-face(lora);
	position: relative;
	padding-bottom: 20px;
	transition: .3s linear;
	&:after {
		content: "";
		display: block;
		width: 50px;
		height: 3px;
		background-color: $green;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%); }
	@include lg-block {
		font-size: 28px; }
	@include sm-block {
		font-size: 24px; }
	@include xxs-block {
		font-size: 20px; } }
.section__body {
	text-align: center;
	margin-top: 70px;
	transition: .3s linear;
	@include xxs-block {
		margin-top: 30px; } }
.section__text {
	font-size: 18px;
	color: $dark;
	transition: .3s linear;
	@include lg-block {
		font-size: 16px; } }
.section__btn {
	width: 100%;
	max-width: 270px;
	font-size: 18px;
	color: #4C628A;
	border: 1px solid #4c628a;
	border-radius: 30px;
	background-color:	transparent;
	padding: 18px 15px;
	transition: .3s linear;
	&:hover {
		border-color: $green;
		background-color: $green;
		color: #ffffff; }
	@include lg-block {
		max-width: 190px;
		font-size: 16px;
		padding: 12px 10px; }
	@include md-block {
		padding: 12px 7px; }
	@include xs-block {
		max-width: 230px;
		font-size: 16px; } }
.section__btn_green {
	background-color: $green;
	border-color: $green;
	color: #fff;
	&:hover {
		border-color: darken($green, 10%);
		background-color: darken($green, 10%); } }

.circle {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	border-radius: 50%;
	@include lg-block {
		display: none; } }
.circle__mini {
	width: 16px;
	height: 16px; }
.circle__small {
	width: 40px;
	height: 40px; }
.circle__average {
	width: 75px;
	height: 75px; }
.circle__left {
  left: 25px;
  bottom: 150px; }
.circle__right {
  right: 25px;
  top: 130px; }
.circle__big {
	width: 138px;
	height: 138px; }
.circle__left_minus {
	left: -60px;
	bottom: 150px; }
.circle__right_minus {
	right: -60px;
	top: 130px; }


.services-slider_prev {
  border-radius: 50%;
  &::before {
    content: "\f053";
    font-family: 'FontAwesome';
    display: block;
    font-size: 26px;
    color:  #000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%); } }
.services-slider_next {
  border-radius: 50%;
  &::before {
    content: "\f054";
    font-family: 'FontAwesome';
    display: block;
    font-size: 26px;
    color:  #000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%); } }
