@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/Roboto-Regular.woff') format('woff'), url('/assets/fonts/Roboto-Regular.ttf') format('ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'Lora';
	src: url('/assets/fonts/Lora-Regular.woff') format('woff'), url('/assets/fonts/Lora-Regular.ttf') format('ttf'), url('/assets/fonts/Lora-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal; }
