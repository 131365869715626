.certificates {
  .circle__right {
    top: 60%; }
  .circle__left {
    top: 40%; } }
.certificat__list {
  display: flex;
  justify-content: space-around;
  padding: 0 30px;
  @include sm-block {
    padding: 0 10px;
    flex-wrap: wrap; }
  @include xxs-block {
    padding: 0; } }
.certificat__item {
  margin: 0 20px;
  width: 270px;
  height: 330px;
  background-color: $blue;
  @include sm-block {
    margin-bottom: 15px; } }
