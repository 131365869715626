.hero {
  padding-top: 100px;
  background: url('/assets/images/hero/hero-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom, 250px 0px;
  @include xxs-block {
    padding-top: 65px;
    min-height: initial;
    background-image: none;
    background: linear-gradient(180deg, #fff 0%, #fff 44%, $blue 44%, $blue 100% ); }
  @include xs-block {
    background: linear-gradient(180deg, #fff 0%, #fff 40%, $blue 40%, $blue 100% ); }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(138, 212, 251, .9); } }
.hero__content {
  position: relative;
  text-align: center;
  @include xxs-block {
    width: 100%;
    text-align: center; } }
.hero__wrap {
  max-width: 500px;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-top: 120px;
  @include lg-block {
    padding-top: 70px; }
  @include sm-block {
    padding-top: 80px; }
  @include xxs-block {
    padding-top: 60px; } }
.hero__head {
  padding-bottom: 80px;
  @include lg-block {
    padding-bottom: 50px; } }
.hero__title {
  padding-bottom: 0;
  &:after {
    display: none; } }
.hero__text {
  padding-bottom: 40px;
  @include xxs-block {
    padding-bottom: 30px; } }
.hero__btn {
  box-shadow: 0 0 5px 1px rgba(0,0,0,.2); }
.hero__forms {
  margin-top: 130px;
  background-color: #94e8b6;
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
  .section__container {
    position: relative;
    z-index: 1; }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -8%;
    width: 116%;
    top: -80px;
    bottom: 0;
    border-radius: 50%;
    padding-top: 10%;
    background-color: #94e8b6;
    box-shadow: 0 -4px 11px 0px rgba(0,0,0,.1);
    @include lg-block {
      top: -70px;
      bottom: 60px; }
    @include xxs-block {
      top: -50px;
      bottom: 240px; } }
  .form {
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    .form__title {
      @include font-face(lora);
      font-size: 20px;
      &:after {
        display: none; }
      @include xxs-block {
        text-align: center; } }
    @include xxs-block {
      .form__wrap {
        justify-content: center; }
      .form__elem {
        max-width: 330px;
        margin-bottom: 15px;
        flex-basis: 100%;
        padding-right: 0; }
      .form__contorls {
        text-align: center;
        flex-basis: 100%; }
      .form__btn {
        max-width: 330px; } } } }
