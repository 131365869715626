.price {
  background-color: $blue;
  padding: 160px 0;
  transition: .3s linear;
  @include lg-block {
    padding: 80px 0; }
  @include sm-block {
    padding: 80px 0; }
  @include xxs-block {
    padding: 60px 0; }
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -9%;
    width: 116%;
    top: -70px;
    border-radius: 50%;
    padding-top: 10%;
    background-color: #ffffff;
    box-shadow: 0 5px 5px 0px rgba(0,0,0,.1);
    @include mdx-block {
      top: -50px; }
    @include sm-block {
      top: -30px; } }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -9%;
    width: 116%;
    bottom: -70px;
    border-radius: 50%;
    padding-top: 10%;
    background-color: #ffffff;
    box-shadow: 0px -5px 5px 0px rgba(0,0,0,.1);
    @include mdx-block {
      bottom: -50px; }
    @include sm-block {
      bottom: -30px; } }
  .circle__big {
    top: 45%; }
  .circle__small {
    bottom: 30%; }
  .section__container {
    position: relative;
    .circle__mini {
      top: 50px; } } }

.price__title {
  margin-bottom: 20px;
  &:after {
    background-color: #fff; } }
.price__table {
  margin-bottom: 70px;
  @include sm-block {
    display: flex;
    justify-content: center; } }
.price__list {
  overflow: hidden;
  display: flex;
  background-color: #f0f5fc;
  border: 1px solid $dark;
  border-radius: 25px 25px 0 0;
  @include sm-block {
    flex-direction: column;
    border-radius: 25px 0 0 25px; } }
.price__list_white {
  border-radius: 0 0 25px 25px;
  background-color: #fff;
  border-top-color: transparent;
  @include sm-block {
    border-top-color: $dark;
    border-left-color: transparent;
    border-radius: 0 25px 25px 0; } }
.price__item {
  flex: 1;
  padding: 19px 10px;
  background-color: #fff;
  border: 1px solid transparent;
  border-right-color: $dark;
  display: flex;
  align-items: center;
  justify-content: center;
  &:last-child {
    border-right-color: transparent;
    @include sm-block {
      border-bottom-color: transparent; } }
  @include sm-block {
    border-right-color: transparent;
    border-bottom-color: $dark; } }


.price__btn {
  margin: 0 15px;
  @include xxs-block {
    margin-bottom: 15px; } }
